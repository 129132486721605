import FontFaceObserver from "./fonts"

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

let inter_font = new FontFaceObserver('Inter'),
    html = document.getElementsByTagName('html')[0];

if (!html.classList.contains('inter-loaded')) {
    inter_font.load().then(function () {
        html.classList.add('inter-loaded');
        setCookie('mpj_inter_font_loaded', 'true', 30);
    });
}